import type { FlattenSimpleInterpolation } from 'styled-components';
import { css, keyframes } from 'styled-components';
import type { TextAlignment } from '@amplience/content-types/typings/c-full-banner';
import { TypographyStyles, colours, media, spacing } from '@storefront/lib-global/stylings';

export const BannerButtonStyles = (variation?: 'PRIMARY' | 'SECONDARY' | 'CUSTOM', buttonColor?: string) => {
  const baseStyles = css`
    ${TypographyStyles.Button.SemiBold}
    border-style: solid;
    border-width: 1px;
    display: block;
    min-height: 50px;
    overflow-wrap: anywhere;
    padding: ${spacing.XXS};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  `;

  const styles = {
    PRIMARY: css`
      background-color: ${colours.DARK_BLUE};
      border-color: ${colours.DARK_BLUE};
      color: ${colours.WHITE};

      &:hover,
      &:focus-visible {
        background-color: ${colours.NAVY_BLUE};
      }
    `,
    SECONDARY: css`
      background-color: ${colours.WHITE};
      border-color: ${colours.BLACK};
      color: ${colours.DARK_BLUE};

      &:hover,
      &:focus-visible {
        background-color: ${colours.LIGHT_BLUE};
      }
    `,
    CUSTOM: css`
      background-color: ${buttonColor};
      border-color: ${buttonColor};

      &:hover,
      &:focus-visible {
        background-color: ${buttonColor};
        opacity: 0.8;
      }
    `,
  };

  return css`
    ${baseStyles}
    ${styles[variation || 'PRIMARY']}
  `;
};

export const ButtonListSingleColumn = css`
  width: 100%;

  @media ${media.greaterThan('lg')} {
    max-width: 340px;
  }
`;

export const ButtonListTripleColumn = css`
  @media ${media.greaterThan('lg')} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${spacing.XS} ${spacing.M};
    max-width: 640px;

    > li {
      flex: 1 1 192px;
      max-width: 192px;
    }
  }
`;

export enum BannerTypes {
  FULL_SCREEN = 'Full Screen',
  FULL_WIDTH = 'Full Width',
  TWO_COLUMN = 'Two Column',
  TWO_COLUMN_LONG_MEDIA = 'Two Column Long Media',
  OVERLAPPING = 'Overlapping',
  MULTIPLE_OVERLAPPING = 'Multiple Overlapping',
}

enum Positions {
  LEFT_TOP = 'left-top',
  LEFT_CENTRE = 'left-centre',
  LEFT_BOTTOM = 'left-bottom',
  CENTRE_TOP = 'centre-top',
  CENTRE_CENTRE = 'centre-centre',
  CENTRE_BOTTOM = 'centre-bottom',
  RIGHT_TOP = 'right-top',
  RIGHT_CENTRE = 'right-centre',
  RIGHT_BOTTOM = 'right-bottom',
}

export const positions = {
  [Positions.LEFT_TOP]: css`
    left: 0;
    margin-left: ${spacing.S};
    margin-top: ${spacing.S};
    top: 0;

    @media ${media.greaterThan('xl')} {
      margin-left: 70px;
      margin-top: 70px;
    }
  `,
  [Positions.LEFT_CENTRE]: css`
    left: 0;
    margin: 0;
    margin-left: ${spacing.S};
    top: 50%;
    transform: translateY(-50%);

    @media ${media.greaterThan('xl')} {
      margin-left: 70px;
    }
  `,
  [Positions.LEFT_BOTTOM]: css`
    bottom: 0;
    left: 0;
    margin-bottom: ${spacing.S};
    margin-left: ${spacing.S};

    @media ${media.greaterThan('xl')} {
      margin-left: 70px;
    }
  `,
  [Positions.CENTRE_TOP]: css`
    left: 50%;
    margin: 0;
    margin-top: ${spacing.S};
    top: 0;
    transform: translateX(-50%);

    @media ${media.greaterThan('xl')} {
      margin-top: 70px;
    }
  `,
  [Positions.CENTRE_CENTRE]: css`
    left: 50%;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
  [Positions.CENTRE_BOTTOM]: css`
    bottom: 0;
    left: 50%;
    margin: 0;
    margin-bottom: ${spacing.S};
    transform: translateX(-50%);

    @media ${media.greaterThan('xl')} {
      margin-bottom: 70px;
    }
  `,
  [Positions.RIGHT_TOP]: css`
    margin-right: ${spacing.S};
    margin-top: ${spacing.S};
    right: 0;
    top: 0;

    @media ${media.greaterThan('xl')} {
      margin-right: 70px;
      margin-top: 70px;
    }
  `,
  [Positions.RIGHT_CENTRE]: css`
    margin: 0 ${spacing.S} 0 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media ${media.greaterThan('xl')} {
      margin-right: 70px;
    }
  `,
  [Positions.RIGHT_BOTTOM]: css`
    bottom: 0;
    margin-bottom: ${spacing.S};
    margin-right: ${spacing.S};
    right: 0;

    @media ${media.greaterThan('xl')} {
      margin-bottom: 70px;
      margin-right: 70px;
    }
  `,
} as { [k in TextAlignment]: FlattenSimpleInterpolation };

const defaultAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const animate = (delayInSeconds = 0.2, durationInSeconds = 0.5, timing = 'ease-out') => css`
  animation-delay: ${delayInSeconds}s;
  animation-duration: ${durationInSeconds}s;
  animation-fill-mode: forwards;
  animation-name: ${defaultAnim};
  animation-timing-function: ${timing};

  @media (prefers-reduced-motion) {
    animation: none;
    opacity: 1;
  }
`;

export const twoColumnTextAlignment = {
  top: 'flex-start',
  centre: 'center',
  bottom: 'flex-end',
} as const;

export const heroBannerAlignment: Record<string, FlattenSimpleInterpolation> = {
  LEFT: css`
    justify-content: flex-start;
  `,
  CENTRE: css`
    justify-content: center;
  `,
  RIGHT: css`
    justify-content: flex-end;
  `,
  LEFTLEFT: css`
    justify-content: flex-start;
  `,
  LEFTCENTRE: css`
    > *:first-child {
      margin: 0;
    }

    > *:last-child {
      margin: 0 auto;
    }
  `,
  LEFTRIGHT: css`
    justify-content: space-between;
  `,
  CENTRELEFT: css`
    flex-direction: row-reverse;

    > *:first-child {
      margin: 0 auto;
    }
  `,
  CENTRECENTRE: css`
    justify-content: center;
  `,
  CENTRERIGHT: css`
    > *:first-child {
      margin: 0 auto;
    }
  `,
  RIGHTLEFT: css`
    flex-direction: row-reverse;
    justify-content: space-between;
  `,
  RIGHTCENTRE: css`
    flex-direction: row-reverse;

    > *:last-child {
      margin: 0 auto;
    }
  `,
  RIGHTRIGHT: css`
    justify-content: flex-end;
  `,
};

export const getOverlappingBannerStyles = (mediaLen: number, mediaPosition: 'Image Left' | 'Image Right') => {
  if (mediaLen === 3) {
    return css`
      margin: -${spacing.M} 0;

      > *:nth-child(1) {
        aspect-ratio: 1/1;
        grid-column: 5/ -1;
        grid-row: ${mediaPosition === 'Image Left' ? '1 / span 9' : '4 / span 9'};
      }

      > *:nth-child(2) {
        grid-column: 4/9;
        grid-row: ${mediaPosition === 'Image Left' ? '8 / -1' : '1 / span 5'};
      }

      > *:nth-child(3) {
        aspect-ratio: 2/3;
        grid-column: 1 / span 4;
        grid-row: 3 / span 8;
      }

      @media ${media.greaterThan('lg')} {
        min-width: calc(644px - 100%);
      }
    `;
  }

  return css`
    margin-bottom: -70px;
    padding: ${spacing.S};

    > *:nth-child(1) {
      aspect-ratio: 1/1;
      grid-column: 1 / span 10;
      grid-row: 1 / span 7;
    }

    > *:nth-child(2) {
      aspect-ratio: 1/1;
      grid-column: 5/-1;
      grid-row: 7/-1;
    }

    @media ${media.greaterThan('lg')} {
      margin-bottom: 0;
      padding-inline: 0;

      > *:nth-child(1) {
        aspect-ratio: 3/4;
        grid-column: ${mediaPosition === 'Image Left' ? '1 / span 9' : '4 / -1'};
        grid-row: 1/-1;
      }

      > *:nth-child(2) {
        grid-column: ${mediaPosition === 'Image Left' ? '7 / -1' : '1 / span 6'};
        grid-row-start: 4;
      }
    }

    @media ${media.greaterThan('xl')} {
      padding: 0;
    }
  `;
};
