import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import type {
  ButtonsAlignment,
  DesktopButtonListLayout,
  MediaFit,
  TextAlignment,
} from '@amplience/content-types/typings/c-full-banner';
import { useIntersectOnce } from '@storefront/lib-global/hooks/useIntersectOnce';
import { useIsComponentInView } from '@storefront/lib-global/hooks/useIsComponentInView';
import { useMediaMatch } from '@storefront/lib-global/hooks/useMediaMatch';
import { useResizeScrollSize } from '@storefront/lib-global/hooks/useResizeScrollSize';
import { FlexibleTextPartial } from '@storefront/lib-global/partials/flexibleText';
import type { MediaPartialProps } from '@storefront/lib-global/partials/media';
import { MediaPartial } from '@storefront/lib-global/partials/media';
import { RichTextPartial } from '@storefront/lib-global/partials/richText';
import {
  TypographyStyles,
  TypographyStylesType,
  colours,
  maxWidthPartial,
  media,
  spacing,
} from '@storefront/lib-global/stylings';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';
import { ButtonListSingleColumn, ButtonListTripleColumn, animate, positions } from '../../utils';
import { FullBannerButton } from './FullBannerButton';
import type { FullBannerComponentProps } from './bannerType';

export type FullWidthBannerProps = FullBannerComponentProps;

const S = {
  FullWidthBannerContainer: styled.div<{ $mediaHeight: number }>`
    height: ${({ $mediaHeight }) => ($mediaHeight > 630 ? $mediaHeight + 40 : 630)}px;
    position: relative;
  `,
  FullWidthMediaContainer: styled.div<{ $mediaFit?: MediaFit }>`
    aspect-ratio: 2/3;
    height: 100%;
    position: relative;
    width: 100%;

    & video {
      height: 100%;
      object-fit: ${({ $mediaFit }) => $mediaFit};
      position: absolute;
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      aspect-ratio: 2/1;
    }
  `,
  FullWidthBannerDetails: styled.div<{
    $position: FlattenSimpleInterpolation;
    $isIntersecting: boolean;
    $buttonListLayout?: string;
  }>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.S};
    max-width: ${({ $buttonListLayout }) => ($buttonListLayout === 'single-column' ? '250px' : '240px')};
    opacity: 0;
    overflow-wrap: break-word;
    position: absolute;
    width: ${({ $buttonListLayout }) => ($buttonListLayout === 'single-column' ? 'unset' : '100%')};
    ${({ $position }) => $position}
    ${({ $isIntersecting }) => ($isIntersecting ? animate() : null)}

    @media ${media.greaterThan('lg')} {
      max-width: ${({ $buttonListLayout }) => ($buttonListLayout === 'single-column' ? '700px' : '640px')};
    }

    @media screen and (prefers-reduced-motion) {
      animation: none;
      opacity: 1;
    }
  `,
  FullWidthBannerTitle: styled(FlexibleTextPartial)`
    margin: 0;
  `,
  FullWidthBannerDescription: styled(RichTextPartial)<{ $textColor?: string }>`
    color: ${({ $textColor }) => $textColor};

    & > p {
      margin: 0;
    }

    & a {
      color: ${({ $textColor }) => $textColor};
    }
  `,
  FullWidthBannerButtonsContainer: styled.ul<{
    $position: FlattenSimpleInterpolation;
    $buttonListLayout?: DesktopButtonListLayout;
  }>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    max-width: 240px;
    padding: 0;
    position: absolute;
    width: 100%;
    ${({ $position }) => $position}
    ${({ $buttonListLayout }) =>
      $buttonListLayout === 'single-column' ? ButtonListSingleColumn : ButtonListTripleColumn}

    > li {
      background-color: ${colours.WHITE};
    }
  `,
  MaxWidthWrapper: styled.div`
    ${maxWidthPartial()}
  `,
};

export function FullWidthBanner({
  bannerTitle,
  bannerDescription,
  bannerButtons,
  bannerMedia,
  buttonsAlignment,
  buttonListLayout = 'single-column',
  textAlignment,
  onClick,
}: FullWidthBannerProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const currentMedia = isDesktop ? bannerMedia?.desktopMedia : bannerMedia?.mobileMedia;
  const { componentRef, isInView } = useIsComponentInView();

  const { elementRef: intersectionRef, isIntersecting } = useIntersectOnce({
    threshold: 0.7,
  });
  const { elementRef: resizeRef, scrollHeight } = useResizeScrollSize({});

  return (
    <S.FullWidthBannerContainer ref={componentRef} $mediaHeight={scrollHeight}>
      <S.FullWidthMediaContainer $mediaFit={currentMedia?.mediaFit}>
        <MediaPartial
          {...(currentMedia as MediaPartialProps)}
          height={100}
          width={100}
          isImageLayoutFill
          mediaFit={currentMedia?.mediaFit}
          videoQuality={currentMedia?.videoQuality}
          altText={currentMedia?.altText}
          key={currentMedia?.media?.name}
          lazyLoading={!isInView}
        />
      </S.FullWidthMediaContainer>
      <S.MaxWidthWrapper>
        <S.FullWidthBannerDetails
          $position={positions[textAlignment as TextAlignment]}
          ref={resizeRef}
          $isIntersecting={isIntersecting}
          $buttonListLayout={buttonListLayout}
        >
          {!!bannerTitle?.text && (
            <S.FullWidthBannerTitle
              {...bannerTitle}
              text={getLocalizedValue(bannerTitle?.text)}
              defaultTextColor={colours.BLACK}
              defaultTextSize={TypographyStylesType.HEADINGS_XX_LARGE}
              ref={intersectionRef}
            />
          )}
          {!!bannerDescription && (
            <S.FullWidthBannerDescription
              text={getLocalizedValue(bannerDescription.richText)}
              fontSize={TypographyStyles.Body.Small.Medium}
              $textColor={bannerDescription.textColor as string}
            />
          )}
        </S.FullWidthBannerDetails>
        {!!bannerButtons?.length && (
          <S.FullWidthBannerButtonsContainer
            $position={positions[buttonsAlignment as ButtonsAlignment]}
            $buttonListLayout={buttonListLayout}
          >
            {bannerButtons.map((button, idx) => (
              <li key={`${button.buttonText}-${idx}`}>
                <FullBannerButton {...button} onClick={onClick} />
              </li>
            ))}
          </S.FullWidthBannerButtonsContainer>
        )}
      </S.MaxWidthWrapper>
    </S.FullWidthBannerContainer>
  );
}

export default FullWidthBanner;
