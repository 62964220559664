import type { MouseEvent } from 'react';
import styled from 'styled-components';
import type {
  ButtonVariation,
  FullBannerButton as FullBannerButtonType,
} from '@amplience/content-types/typings/c-full-banner';
import { FlexibleTextPartial } from '@storefront/lib-global/partials/flexibleText';
import { LinkPartial } from '@storefront/lib-global/partials/link';
import { getLocalizedValue } from '@storefront/lib-global/utils/transformers';
import { BannerButtonStyles } from '../../utils';

type BannerButtonProps = { onClick?: (event: MouseEvent<HTMLAnchorElement>) => void } & FullBannerButtonType;

const S = {
  Button: styled(LinkPartial)<{ $variation?: ButtonVariation; $buttonColor?: string }>`
    ${({ $variation, $buttonColor }) => BannerButtonStyles($variation, $buttonColor)}
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    > * {
      margin: 0;
    }
  `,
};

export function FullBannerButton({
  buttonText,
  buttonVariation,
  buttonColor,
  buttonTextColor,
  buttonTextTransform,
  buttonTextSize,
  link,
  onClick,
}: BannerButtonProps) {
  return (
    <S.Button $variation={buttonVariation} $buttonColor={buttonColor} {...link} onClick={onClick}>
      <FlexibleTextPartial textColor={buttonTextColor} textTransform={buttonTextTransform} textSize={buttonTextSize}>
        {getLocalizedValue(buttonText)}
      </FlexibleTextPartial>
    </S.Button>
  );
}

export default FullBannerButton;
